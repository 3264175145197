
import HourglassLoader from "@/components/loader/HourglassLoader.vue";
import Pagination from "@/components/mini/Pagination.vue";
import CountSelector from "@/components/mini/CountSelector.vue";
import { onBeforeMount, ref, defineComponent } from "vue";
import { apiGet } from "@/api";
import { useRoute } from "vue-router";
import { Toast } from "@/utils/notify";
import CoinTypeRow from "@/views/coins/constituent/CoinTypeRow.vue";

export default defineComponent({
  name: "CoinTypes",
  components: {
    CoinTypeRow,
    HourglassLoader,
    Pagination,
    CountSelector,
  },
  setup() {
    const loading = ref(false);
    const data = ref(null);

    const route = useRoute();

    const RefreshData = async () => {
      loading.value = true;
      try {
        const response = await apiGet(
          `/admin/coin/type/`,
          (data.value as any)?.meta ?? {}
        );
        data.value = response.data.data;

        console.log(data.value);

        Toast.fire({
          icon: "success",
          title: "Fetched Successfully",
        }).then();
      } catch (e) {
        Toast.fire({
          icon: "warning",
          title: "Unable to fetch data: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    onBeforeMount(RefreshData);

    return {
      RefreshData,
      route,
      data,
      loading,
    };
  },
});
